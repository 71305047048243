import React from "react"
import { graphql } from "gatsby"
import BlockContent from "../components/block-content"
import Container from "../components/main/container"
import GraphQLErrorList from "../components/main/graphql-error-list"
import SEO from "../components/main/seo"
import Layout from "../containers/layout"
import { responsiveTitle1 } from "../styles/typography.module.css"

export const query = graphql`
  query AboutPageQuery {
    page: sanityPage(_id: { eq: "about" }) {
      title
      _rawBody
    }
  }
`

const AboutPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data && data.page

  return (
    <Layout>
      <SEO title={page.title} />
      <Container>
        <h1 className={responsiveTitle1}>{page.title}</h1>
        <BlockContent blocks={page._rawBody || []} />
      </Container>
    </Layout>
  )
}

export default AboutPage
